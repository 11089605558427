import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { banner, icon, background, title, text } from './gift-finder-banner.module.scss';

import useTranslations from '../../hooks/use-translations';

import GiftFinderIcon from '../../assets/images/svg/gift-finder.svg';

type TStaticQueryResult = Record<'giftfinder', { pathname: string } | null>;

const GiftFinderBanner = () => {
    const t = useTranslations('GiftFinderBanner');
    const { giftfinder } = useStaticQuery<TStaticQueryResult>(query);

    return (
        <Link className={banner} to={giftfinder?.pathname || '/404/'}>
            <div className={background} />
            <GiftFinderIcon className={icon} />
            <h2 className={title}>{t.title}</h2>
            <p className={text}>{t.text}</p>
        </Link>
    );
};

const query = graphql`
    query {
        giftfinder: page(type: { eq: "gift-finder" }) {
            pathname
        }
    }
`;

export default GiftFinderBanner;
