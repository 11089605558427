import React from 'react';

import {
    grid,
    list,
    previewList,
    fullList,
    titleText,
    subtitleText,
    button,
    contentText,
} from './menu-tiles.module.scss';

import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import Section from '../hoc/section';
import LinkCard from '../molecules/link-card';
import GiftFinderBanner from '../atoms/gift-finder-banner';

export interface ICategoryTiles extends ISection {
    items: {
        links: INavLink[];
    };
}

interface ICategoryTilesProps {
    className?: string;
    section: ICategoryTiles;
    TitleTag?: React.ElementType;
}

const MenuTiles: React.FC<ICategoryTilesProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        sectionId,
        items: { links },
        content: { texts, buttons },
        css,
        style,
    } = section;

    const cardNameTag = getLessImportantHeading(TitleTag);

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: button,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            buttonText={buttons[0]?.text}
            buttonUrl={buttons[0]?.url}
            css={css}
            style={style}
        >
            <ul className={`${list} ${section.settings?.isMain ? fullList : previewList}`}>
                {links.map((link) => {
                    return (
                        <li key={`link-item-${link.linkId}`}>
                            <LinkCard link={link} NameTag={cardNameTag} />
                        </li>
                    );
                })}
                <li>
                    <GiftFinderBanner />
                </li>
            </ul>
        </Section>
    );
};
export default MenuTiles;
